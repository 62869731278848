<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="search"
          placeholder="Buscar por nombre de usuario"
          outlined
          :append-icon="icons.mdiMagnify"
          @keyup="handleSearch"
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>
        <v-select
          style="width: 0px"
          class="mb-4"
          :items="getUserRole.id === 5 ? roleList : roleList.filter(item => item.value !== 5)"
          hide-details="true"
          label="Rol"
          item-text="name"
          item-value="value"
          @change="setRoleFilter($event)"
          outlined
          dense
        ></v-select>
        <!-- v-model="stateFilterRole" -->
        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="$refs.user_form_modal.openModal()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Agregar Usuario</span>
          </v-btn>
        </div>
      </v-card-text>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="userListTable"
        :search="search"
        :page="current_page"
        :server-items-length="totalUserListTable"
        @update:page="changingUsersPage($event)"
        @update:items-per-page="changingUsersItemsPerPage($event)"
        :footer-props="{
          'items-per-page-options': [
            10,
            20,
            50,
          ],
          'items-per-page-text': 'Items por página',
          'page-text': '{0}-{1} de {2}',
        }"
      >
        <!-- NO data -->
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No se encontraron
              <strong>usuarios</strong>
            </v-list-item-title>
          </v-list-item>
        </template>

        <!-- name -->
        <template #[`item.name`]="{ item }">
          <v-btn
            class="no-uppercase"
            text
            @click="$refs.user_details_modal.openModal(item.id)"
          >
            {{ item.name }}
          </v-btn>
        </template>

        <!-- role -->
        <template #[`item.role`]="{ item }">
          {{ `${item.role}` }}
        </template>

        <!-- veterinary_clinic -->
        <template v-slot:item.veterinary_clinics="{ item }">
          <div v-if="item.veterinary_clinics && item.veterinary_clinics.length">
            <v-chip 
              v-for="(clinic, index) in item.veterinary_clinics"
              class="ms-1"
              :key="index"
              x-small
              outlined
            >
              {{ clinic.name }}
            </v-chip>
          </div>
          <div v-else class="ms-6"> - </div>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <v-icon class="me-2" @click="$refs.user_form_modal.openModal(item.id)" color="primary"
          :disabled="getUserRole.id !== 5 && item.role === 'Super Administrador'"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-icon @click="deleteModal(item)" color="error"
          :disabled="getUserRole.id !== 5 && item.role === 'Super Administrador'">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>
      </v-data-table>

      <!-- DELETE dialog -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title>
            Eliminar Usuario? <b class="ps-3">{{ `'${editedItem.name}'` }}</b>
           </v-card-title>
           <v-card-subtitle v-if="editedItem.role === 'Veterinario'"
           class="mt-3"
           > 
            Tenga en cuenta que los datos del veterinario estaran ligados a las solicitudes y con ellas, a los reportes y pagos. 
            Si va a eliminarlo asegurese que no existan solicitudes asociadas y en estado de procesamiento o finalizadas.
           </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="closeDelete"> Cancelar </v-btn>
            <v-btn color="success" @click="deleteUserConfirm"> Eliminar </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <UserDetailModal ref="user_details_modal" />

      <user-form-modal 
      ref="user_form_modal" 
      @display-alert="displayAlert"
      @reload-user-list="getUsersList()" 
      />

      <AlertPopUp ref="alert_pop_up"/>

    </v-card>
  </div>
</template>

<script>
import debounce from "../../plugins/debounce.js";
import { ref } from "@vue/composition-api";
import UserFormModal from "@/components/Users/UserFormModal";
import UserDetailModal from "./UserDetailModal.vue";
import AlertPopUp from "../AlertPopUp.vue";

import {
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiMagnify,
  mdiPencilOutline,
} from "@mdi/js";

export default {
  components: {
    UserFormModal,
    UserDetailModal,
    AlertPopUp
},
  data: () => {
    return {
      current_page: 1,
      page_size: 10,
      roleFilter: -1,
      search: "",
      userList: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      showAlert:false,
    };
  },
  methods: {
    handleSearch: debounce(function () {
      this.current_page = 1;
      this.getUsersList();
    }, 400),
    changingUsersPage(e) {
      this.current_page = e
      this.getUsersList();
    },
    changingUsersItemsPerPage(e) {
      this.page_size = e
      this.getUsersList();
    },
    setRoleFilter(value) {
      this.current_page = 1;
      this.roleFilter = value 
      this.getUsersList();
    },
    async getUsersList() {
      const data = {
        page_size: this.page_size,
        page: this.current_page,
        search: this.search,
        summary: true,
        role: this.roleFilter,
      };
      try {
        const response = await this.$api.getUsersList(data);

        this.userListTable = response.results;
        this.totalUserListTable = response.count;
        // console.warn(this.userListTable);
        // this.totalUserListTable = response.count
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    deleteModal(item) {
      this.editedIndex = this.userList.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
      //   console.log(this.tableColumns)
      // console.warn(this.editedItem.id);
    },
    async deleteUserConfirm() {
      try {
        const response = await this.$api.deleteUser(this.editedItem.id);
        this.getUsersList();
        this.$refs.alert_pop_up.displayAlert({ message: 'Usuario eliminado correctamente', type: 'success' })
        this.close();
      } catch (e) {
        console.log(e);
        this.showAlert = true
        this.$refs.alert_pop_up.displayAlert({ message: 'No se pudo eliminar', type: 'error' })
      }
        this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
      this.editedItem = { ...this.defaultItem };
      this.editedIndex = -1;
      });
    },
    //display alerts from childs
    displayAlert(message, type){
      this.$refs.alert_pop_up.displayAlert(message, type)
    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDelete();
    },
  },
  mounted() {
    // this.loading = true
    this.getUsersList();
  },
  computed: {
      getUserRole() { return this.$store.getters["session/getUserRole"](); },
  },
  setup() {
    const totalUserListTable = ref(0);
    const userListTable = ref([]);

    const tableColumns = [
      { text: "NOMBRE", value: "name", align: "center" },
      { text: "ROL", value: "role", align: "center" },
      {
        text: "VETERINARIA",
        value: "veterinary_clinics",
        align: "left",
        sortable: false,
      },
      { text: "EDICION", value: "actions", align: "center", sortable: false, width: "120px" },
    ];

    const roleList = [
            { name: 'Todos', value: -1 },
            { name: "Super Administrador", value: 5 },
            { name: "Administrador", value: 1 }, 
            { name: "Veterinario", value: 0 },
          ]

    const statusColor = {
      /* eslint-disable key-spacing */
      Current: "primary",
      Professional: "success",
      Rejected: "error",
      Resigned: "warning",
      Applied: "info",
      /* eslint-enable key-spacing */
    };

    return {
      totalUserListTable,
      roleList,
      userListTable,
      tableColumns,

      status: {
        1: "Current",
        2: "Professional",
        3: "Rejected",
        4: "Resigned",
        5: "Applied",
      },
      statusColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMagnify
      },
    };
  },
};
</script>

<style scoped>
.no-uppercase {
  text-transform: unset !important;
}
</style>
