<template>
  <div>
    <v-dialog v-model="showModal" width="auto" minHeight="1200">
      <v-card>
        <v-card-title class="text-h5 text-center grey lighten-2">
            <v-icon class="me-2" color="primary">
            {{ icons.mdiAccountCircleOutline }}
            </v-icon> 
           {{ userData.name }}
        </v-card-title>
        
        <v-divider></v-divider>
        
        <v-card-text>
            <strong class="me-2">{{ 'DIRECCION:'}}</strong> {{ userData.address ? userData.address : '-'}}
        </v-card-text>

        <v-card-text >
            <strong class="me-2">{{ 'EMAIL:'}}</strong> {{ userData.email ? userData.email : '-' }}
        </v-card-text>

        <v-card-text>
            <strong class="me-2">{{ 'TELEFONO:'}}</strong> {{ userData.phone ? userData.phone : '-'}}
        </v-card-text>

        <v-card-text>
            <strong class="me-2">{{ 'CUIT:'}}</strong> {{ userData.cuit ? userData.cuit : '-' }}
        </v-card-text>

        <v-card-text>
            <strong class="me-2">{{ 'METODO DE PAGO:'}}</strong> {{ userData && userData.payment_method ? userData.payment_method.label : '-' }}
        </v-card-text>

      </v-card>      
    </v-dialog>
  </div>
</template>

<script>
import {
    mdiAccountCircleOutline
} from '@mdi/js'

export default {
    data() {
        return {
            showModal: false,
            loading: true,
            userData: {}
        }
    },
    computed: {
        
    },
    methods: {
        async openModal(id) {

            this.loading = true
            try {
                const user = await this.$api.getUser(id)

                this.loading = false
                this.showModal = true
                this.userData = user.data

            } catch (e) {
                console.log(e);
            }
        },

        close() {
            this.showModal = false;
            this.loading = true
        },
    },
    setup(){

        return{
        icons: {
            mdiAccountCircleOutline
      },
        }
    }
}
</script>

<style lang="scss" scoped>

</style>